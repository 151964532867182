html, body, #root {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-y: hidden;
}

body {
	font-family: Arial, sans-serif;
	font-size: 16px;
	background-color: #fafafa;
}

.logo {
	font-size: 16px;
	margin-bottom: 30px;
}

.input-container {
	grid-row: 2;
	grid-column: 1;
	display: flex;
	flex-direction: row;
}

#input-btn {
	flex-grow: 0;
	position: relative;
	top: 1px;
	right: 31px;
	width: 30px;
	height: 30px;
	border-radius: 0;
	border: 1px solid #ccc;
	border-top: none;
	border-right: none;
	background: #eee;
}

.input {
	font-size: 16px;
	margin: 0;
	flex-grow: 1;
	border: 1px solid #ccc;
	outline: none;
	background-color: #fff;
	resize: none;
	padding: 30px;
}

.input:focus {
	border-color: #aaa;
}

.output {
	grid-row: 2;
	grid-column: 2;
	border: 1px solid #ccc;
	font-size: 24px;
	background-color: #fff;
	overflow-x: scroll;
	padding: 30px;
}

.bar {
	grid-row: 1;
	grid-column: 1 / 3;
}

.bar button {
	margin-left: 5px;
}

button:not(#input-btn):disabled {
	color: #888;
	background-color: #eee !important;
	cursor: not-allowed;
}

button:not(#input-btn) {
	font-weight: bold;
	border-radius: 5px;
	padding: 2px 10px;
	font-size: 15px;
	background-color: #eee;
	color: #333;
	border: 2px solid #ccc;
}

button:not(#input-btn):hover {
	background-color: #ddd;
}

button:not(#input-btn):active {
	background-color: #cfcfcf;
}

.bar .helpButton {
	border-radius: 100px !important;
	padding: 2px 9px !important;
}

.main {
	display: grid;
	padding: 20px;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 30px 1fr;
	height: calc(100% - 40px);
	row-gap: 15px;
}

.browsing {
	grid-template-columns: 0px 2fr 1fr;
}

.browsing #input-btn {
	display: none;
}

#browser {
	display: none;
}

#browser button {
	margin-right: 5px;
}

.browsing #browser {
	display: initial;
	grid-row: 1/3;
	grid-column: 3;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-left: 30px;
	overflow-y: auto;
}

.browser-buttons {
	margin: 10px;
}



.browser-doc {
	background-color: #fafafa;
	border: 2px solid #ccc;
	border-radius: 5px;
	margin: 10px;
	padding: 8px 16px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.browser-doc > *:not(.browser-doc:first-child) {
	margin-left: 10px;
}

.browser-doc:not(.browser-current):hover {
	background-color: #f3f3f3;
}

.browser-current {
	background-color: #eee;
}

.hidden {
	grid-template-columns: 0px 1fr;
	column-gap: 0;
}

.hidden .input {
	display: none;
}

.hidden #input-btn {
	position: relative;
	border: 1px solid #ccc;
	border-left: none;
	border-top: none;
	right: unset;
	left: 1px;
	flex-shrink: 0;
	width: max-content;
}

.bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.fileName {
	border-radius: 5px;
	text-decoration: dotted underline;
}

.fileName:hover {
	color: #555;
}

.fileNameInput {
	border-radius: 5px;
	padding: 2px 10px;
	font-size: 15px;
	color: #333;
	border: 2px solid #ccc;
}

.modal-overlay {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background: #00000055;
}

.modal {
	background: #fff;
	margin: 120px auto;
	width:500px;
	max-width: 70%;
	padding: 10px 40px;
	border-radius: 10px;
	box-shadow: 0 5px 20px #0004;
}

.modal button {
	margin-right: 5px;
	margin-bottom: 10px;
}

.delete-button {
	margin-left: auto !important;
	margin-right: -8px !important;
	color: #555 !important;
}